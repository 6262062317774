<template>
  <div class="page-with-title">
    <page-title>
      <h1>Nova música</h1>
    </page-title>
    <form
      method="post"
      class="p-4"
      @submit.prevent="submit"
    >
    <div class="row">
      <div
        class="col-md-3"
        :class="{'has-error': $v.form.song.titulo.$dirty && $v.form.song.titulo.$invalid}"
      >
        <label>Titulo</label>
        <input
          v-model="form.song.titulo"
          type="text"
          class="form-control"
          @click="$v.form.song.titulo.$touch()"
        >
      </div>
      <div
        class="col-md-3"
        :class="{'has-error': $v.form.song.id_artista.$dirty && $v.form.song.id_artista.$invalid}"
      >
        <label>Artista</label>
        <auto-complete
          @selected="a => form.song.id_artista = a.id_artista"
          @click="$v.form.song.id_artista.$touch()"
        />
      </div>
      <div class="col-md-2">
        <label>Compositores</label>
        <input
          v-model="form.song.compositores"
          type="text"
          class="form-control"
        >
      </div>
      <div class="col-md-2">
        <label>Ano</label>
        <input
          v-model="form.song.ano"
          type="text"
          class="form-control"
        >
      </div>
      <div class="col-md-2">
        <label>Cód. Vídeo</label>
        <input
          v-model="form.song.cod_video"
          type="text"
          class="form-control"
        >
      </div>
      <div
        :class="{'has-error': $v.form.lyrics.letra.$dirty && $v.form.lyrics.letra.$invalid}"
        class="col-12 mt-3"
      >
        <label>Letra</label>
        <textarea
          v-model="form.lyrics.letra"
          class="form-control"
          rows="20"
          @click="$v.form.lyrics.letra.$touch()"
        />
      </div>
    </div>
    <div class="mt-4 mb-3">
      <button
        :disabled="$v.$invalid"
        class="btn btn-2 btn-bigger"
      >
        <i class="fa fa-check"></i> <b>Salvar</b>
      </button>
    </div>
  </form>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import AutoComplete from '@/components/utils/AutoComplete'
  import { validationMixin } from 'vuelidate'
  import songStoreValidation from '@/validations/songStoreValidation'
  import { storeSong } from '@/api/songs'

  export default {
    data: () => ({
      form: {
        song: {},
        lyrics: {cod_tipo: '0'}
      }
    }),
    components: {
      PageTitle,
      AutoComplete
    },
    mixins: [validationMixin],
    validations: songStoreValidation,
    methods: {
      async submit() {
        const res = await storeSong(this.form)

        this.$router.push({
          name: 'songShow',
          params: {id: res.song.id_musica}
        })
      }
    }
  }
</script>