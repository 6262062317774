<template>
  <div class="main-box">
    <input
      v-model="query"
      type="text"
      class="form-control"
      @input="isTyping = true; typing()"
      @click="$emit('click')"
    >
    <div
      v-if="isMenuVisible"
      class="menu-box"
    >
      <div class="fa-3x loading-icon" v-if="isLoading">
        <i class="fa fa-spinner fa-pulse" />
      </div>
      <ul
        v-if="!isLoading"
      >
        <li
          v-for="artist in artists"
          :key="artist.id_artista"
          @click="select(artist)"
          class="item"
        >
          <div>
            <img :src="artist.avatar">
          </div>
          <span>{{ artist.artista }} - <b>ID: {{ artist.id_artista }}</b></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  const _ = require('lodash')
  import { searchArtists } from '@/api/artists'

  export default {
    props: ['data'],
    data() {
      return {
        isTyping: false,
        isLoading: false,
        query: this.data,
        artists: []
      }
    },

    watch: {
      data: function(v) {
        this.query = v
      },

      query: _.debounce(function() {
        this.isTyping = false
      }, 800),

      isTyping: async function(val) {
        this.$emit('changed')

        if (!val && this.query.length >= 2) {
          this.isLoading = true

          this.artists = await searchArtists(this.query)
          
          this.isLoading = false
        }
      }
    },

    computed: {
      isMenuVisible() {
        return this.artists.length || this.isLoading
      }
    },

    methods: {
      typing() {
        this.artists = []
      },

      select(artist) {
        this.query = artist.artista
        this.typing()
        this.$emit('selected', artist)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/styles/variables";

  .form-control {
    position: relative;
    z-index: 2;
  }

  .main-box {
    position: relative;
  }
  
  .menu-box {
    z-index: 1;
    position: absolute;
    width: 100%;
    margin-top: -20px;
    border: 2px solid $inputBgColor;
    background: $pageTitleBgColor;
    border-top: 0;
    padding: 30px 0 15px 0;
    box-shadow: 0 3px 5px rgba(#000, .15);

    .loading-icon {
      text-align: center;
      font-size: 28px;
    }

    li {
      display: flex;
      align-items: center;
      padding: 7px 10px;
      cursor: pointer;

      &:hover {
        background: $inputFocusBgColor;
      }

      img {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        margin-right: 8px;
      }
    }
  }
</style>