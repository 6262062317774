const { required, minLength } = require('vuelidate/lib/validators')

export default {
  form: {
    song: {
      titulo: {
        required,
        minLength: minLength(3)
      },
      id_artista: {
        required,
      }
    },
    lyrics: {
      letra: {
        required
      }
    }
  }
}